import './ShoppingCart.css'
import React from 'react'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
import CartSide from './components/CartSide'
import { useQuery } from '@tanstack/react-query'
import { getShoppingCart } from '@services/api.service'

export default function ShoppingCart() {

    const {
        isPending,
        error,
        data,
    } = useQuery({
        queryKey: ['getShoppingCart'],
        queryFn: getShoppingCart,
    })

    console.log('ShoppingCart', data)

    return (
        <>
            <BannerSection
                color='#fff'
                title='Shopping Cart'
                background='/images/banner-contact.webp'
            />
            <Navigation
                title='Shopping Cart'
                titleLink='/shopping-cart'
            />
            <div className="new-content">
                <div className="new-list" id='new-list'>
                    <div className="column-header">
                        <h3>Shopping Cart</h3>
                    </div>
                    <div className='new-content-list'>
                        {/* {newsList.data && newsList.data.map((item, index) => (
                            <NewItem
                                key={index}
                                index={index}
                                currentRow={item}
                            />
                        ))} */}
                    </div>
                </div>
                <CartSide />
            </div>
        </>
    )
}
