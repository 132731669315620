import React from 'react'
import { config } from '../Config'
import Iframe from 'react-iframe'
import { Popover, Button } from 'antd'
import { FaMapMarkerAlt } from 'react-icons/fa'

export default function TopLocation({
    title
}) {
    return (
        <Popover placement="bottomRight" title={
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <h3>Location</h3>
                <Button onClick={() => {
                    window.open(config.google_map_button, '_blank')
                }}>
                    Open in Google Maps
                </Button>
            </div>
        } content={
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
            }}>
                <div>{config.location[0]}</div>
                <Iframe url={config.google_map}
                    width="450px"
                    height="300px"
                    id=""
                    className="google-map"
                    display="block"
                    position="relative" />
            </div>
        }>
            <div className='topline-location'>
                <FaMapMarkerAlt size={24} />
                {title || 'Location'}
            </div>
        </Popover>
    )
}
