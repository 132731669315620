import React from 'react'
import { BiArrowToTop } from 'react-icons/bi'
import './BackToTopButton.css'

const BackToTopButton = () => {
  const [showBackToTop, setShowBackToTop] = React.useState(false)
  window.addEventListener('scroll', () => {
    setShowBackToTop(window.scrollY > 200)
  })
  return (
    <>
      {showBackToTop ? (
        <button
          className="backToTop"
          onClick={() => {
            window.scrollTo(0, 0)
          }}
        >
          <BiArrowToTop size={24} />
        </button>
      ) : null}
    </>
  )
}

export default BackToTopButton
