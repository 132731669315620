import React from 'react'
import './EasyToBuy.css'
import MyImage from '../../../components/MyImage'

export default function EasyToBuy() {
    return (
        <section className='steps-wrapper'>
            <div className='steps-content'>
                <div className='steps-logo'>
                    <MyImage src='/images/logo.webp' width={200} alt='logo' />
                    {/* <img src='/images/logo.webp' width={200} alt='logo' /> */}
                    <h3>AS EASY AS 1, 2, 3</h3>
                </div>
                <div className='steps-item'>
                    <MyImage src='/images/easy-01.webp' width={150} alt='step 1' />
                    {/* <img src='/images/easy-01.webp' width={150} alt='step 1' /> */}
                    <div>
                        <h3>1. Find your tire size</h3>
                        {/* <p>Choose the tire you want</p> */}
                    </div>
                </div>
                <div className='steps-item'>
                    {/* <img src='/images/easy-02.webp' width={150} alt='step 2' /> */}
                    <MyImage src='/images/easy-02.webp' width={150} alt='step 2' />
                    <div>
                        <h3>2. Choose a matching rim</h3>
                        {/* <p>Choose the tire you want</p> */}
                    </div>
                </div>
                <div className='steps-item'>
                    {/* <img src='/images/easy-03.webp' width={150} alt='step 3' /> */}
                    <MyImage src='/images/easy-03.webp' width={150} alt='step 3' />
                    <div>
                        <h3>3. Get it installed</h3>
                        {/* <p>Choose the tire you want</p> */}
                    </div>
                </div>
            </div>
        </section>
    )
}
