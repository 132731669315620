/**
 * Loading Component, used for loading page.
 * @author Dean Yang
 * @version Jan 8, 2023
 */
import './Loading.css'

export default function Loading() {
  return (
    <div className="loading">
      <img src="/images/logo.webp" width={200} alt="loading" />
      <h3>Loading</h3>
    </div>
  )
}
