// @ts-nocheck
import React, { useEffect } from 'react'
import BannerSection from '@components/BannerSection'
// import Navigation from '@components/Navigation'
import { useLocation } from "react-router-dom"
import './Product.css'
import { useSelector, useDispatch } from 'react-redux'
import ProductList from './components/ProductList'
import { getProductList } from '@services/api.service'
import { setProductsList } from '@reducers/contentReducer'
import { useParams } from 'react-router-dom'
import ProductDetail from './ProductDetail'

import { ProductTypes } from '@data/ProductCode'
import BrandsData from '@data/BrandsData'
import ProductSeoData from 'data/ProductSeoData'

export default function Product() {

    const { type, id, title } = useParams()

    // console.log('type', type, id)
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    // const [loadedType, setLoadedType] = useState('')
    const productsTypeList = useSelector((state) => state.content.productsTypeList.filter(item => item.is_wheel === 0))
    const productsFilter = useSelector((state) => state.content.productsFilter)
    const [pageTitle, setPageTitle] = React.useState('Tires')
    const [seoData, setSeoData] = React.useState({ title: '', desc: '', h1: '', link: '' })

    const user = useSelector((state) => state.user.userinfo)

    useEffect(() => {
        async function fetchData() {
            try {
                let tmpData = { title: 'Tires', desc: '', h1: 'Tires', link: '' }
                if (type === 'list' && !id) {
                    setPageTitle('Tires')
                }
                let types = productsFilter.types
                let brands = productsFilter.brands
                if (type === 'brand' && id) {
                    const findBrand = BrandsData.brands.find(item => item.short === id)
                    if (findBrand) {
                        brands = [findBrand.title]
                        setPageTitle(findBrand.title)
                        tmpData = {
                            title: findBrand.seo_title,
                            desc: findBrand.seo_desc,
                            h1: findBrand.seo_h1,
                        }
                    }
                }
                // const tmpTitle = '{width}/{profile} R{rim} Tires | {width} {profile}R{rim} | {width} {profile} {rim} | {width}{profile}{rim} Car Tire for Sale at a Low Price'
                // const tmpDesc = 'The best and cheap {width} {profile}R{rim} tyres for sale at ☛ Tiremaxx has it all: winter/snow, all-weather, all-season, summer, all-terrain and mud terrain tires.'
                // const tmpH1 = 'Sale of car tires in size {width}/{profile} R{rim}'
                // const link = 'https://www.tiremaxx.ca/tires/list/{id}'
                let width = productsFilter.width
                let profile = productsFilter.profile
                let rim = productsFilter.rim
                let txtTitle = ''
                if (type === 'list' && id) {
                    console.log('id', id)
                    const idLower = id.toLowerCase()
                    if (idLower.length === 8 && idLower.indexOf('r') !== -1) {
                        width = Number(idLower.slice(0, 3))
                        profile = Number(idLower.slice(3, 5))
                        rim = Number(idLower.slice(6, 8))
                        txtTitle = idLower
                        // replace all {width}, {profile}, {rim} with real values
                    } else if (idLower.length === 11 && idLower.indexOf('x') !== -1 && idLower.indexOf('r') !== -1) {
                        let tmpArr = idLower.split('x')
                        width = Number(tmpArr[0])
                        tmpArr = tmpArr[1].split('r')
                        profile = Number(tmpArr[0])
                        rim = Number(tmpArr[1])
                    } else {
                        const findType = ProductTypes.find(item => item.short === idLower)
                        if (findType) {
                            types = [findType.id]
                            setPageTitle(findType.title)
                        }
                    }
                }
                if (type === 'size' && id && id.length === 8 && id?.toLowerCase().indexOf('r') !== -1) {
                    width = Number(id.slice(0, 3))
                    profile = Number(id.slice(3, 5))
                    rim = Number(id.slice(6, 8))
                    txtTitle = id
                    // dispatch(setProductsFilterSize({ width, profile, rim }))
                }
                if (type === 'width' && id && id.length === 3) {
                    width = Number(id)
                    txtTitle = id
                }

                if (type === 'profile' && id && id.length === 2) {
                    profile = Number(id)
                    txtTitle = id
                }

                if (type === 'rim' && id && id.length === 2) {
                    rim = Number(id)
                    txtTitle = id
                }
                if (title && ['list', 'size', 'width', 'profile', 'rim'].includes(type)) {
                    const findType = ProductTypes.find(item => item.short === title)
                    if (findType) {
                        types = [findType.id]
                        txtTitle += ' ' + findType.title
                    }
                }
                if (txtTitle) {
                    setPageTitle(txtTitle)
                }
                if (width && profile && rim) {
                    tmpData = {
                        title: `${width}/${profile} R${rim} Tires | ${width} ${profile}R${rim} | ${width} ${profile} ${rim} | ${width}${profile}${rim} Car Tire for Sale at a Low Price`,
                        desc: `The best and cheap ${width} ${profile}R${rim} tyres for sale at ☛ Tiremaxx has it all: winter/snow, all-weather, all-season, summer, all-terrain and mud terrain tires.`,
                        h1: `Sale of car tires in size ${width}/${profile} R${rim}`,
                    }
                    setPageTitle(tmpData?.title)
                }
                if (id) {
                    tmpData.link = `https://www.tiremaxx.ca/tires/list/${id}`
                }
                setSeoData(tmpData)
                const params = {
                    ...productsFilter,
                    username: user?.username || '',
                    token: user?.token || '',
                    pathname: pathname,
                    types: types,
                    brands: brands,
                    width: width,
                    profile: profile,
                    rim: rim,
                    paramType: type,
                    paramId: id,
                    paramTitle: title
                }
                // 设置SEO
                if (type === 'list' && id) {
                    const findSeo = ProductSeoData.find(item => item.id === id)
                    if (findSeo) {
                        console.log('findSeo', findSeo)
                        setSeoData(findSeo)
                    }
                }
                // dispatch(setProductsFilterSize({ width, profile, rim }))
                // console.log('params', params)
                const list = await getProductList(params)
                dispatch(setProductsList(list))
            } catch (err) {
                console.log(err?.data?.message || 'get product list error')
            }
        }
        fetchData()
    }, [dispatch, productsFilter, user, pathname, type, id, title])

    return (
        <>
            <BannerSection
                color='#fff'
                title='Tires'
                background='/images/banner-products.webp'
            />
            {type === 'detail' ? (
                <ProductDetail
                    user={user}
                    pathname={pathname}
                    productsTypeList={productsTypeList}
                />
            ) : (
                <ProductList user={user} pageTitle={pageTitle} seoData={seoData} />
            )}
        </>
    )
}
