/**
 * contentReducer.ts, it handles content actions, including init content list
 * @author Dean Yang
 * @version Jan 1, 2023
 */
import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    userinfo: null
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        logout: (state) => {
            state.userinfo = null
            localStorage.removeItem('userinfo')
        },
        setUser: (state, action) => {
            state.userinfo = action.payload
            localStorage.setItem('userinfo', JSON.stringify(action.payload))
        }
    }
})

export const { logout, setUser } = userSlice.actions

export default userSlice.reducer