import React from 'react'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
import ServiceSection from '@components/ServiceSection'
// import { useSelector } from 'react-redux'
import { ContentCode } from '@data/ContentData'
import './Wheels.css'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import ContentDataHook from '@hooks/ContentData.hook'

const wheels = [
    {
        id: 1,
        title: '1/2oz Wheel Balance Weight',
        description: '<ul><li>6 units/strip</li><li>84g/strip</li><li>30 strips/box</li></ul>',
        price: '$29.47',
        image: '/images/wheel-balance-c.webp'
    },
    {
        id: 2,
        title: '1/4oz Wheel Balance Weight',
        description: '<ul><li>12 units/strip</li><li>84g/strip</li><li>50 strips/box</li></ul>',
        price: '$44.38',
        image: '/images/wheel-balance-a.webp'
    },
    {
        id: 3,
        title: '1oz Wheel Balance Weight',
        description: '<ul><li>6 units/strip</li><li>168g/strip</li><li>30 strips/box</li></ul>',
        price: '$55.78',
        image: '/images/wheel-balance-b.webp'
    },
]

export default function WheelBalanceWeights() {
    // get contents from redux
    // const contents = useSelector((state) => state.content.contents)
    // const productTypeList = useSelector((state) => state.content.productsTypeList)
    // console.log('productTypeList', productTypeList)
    // find about and mission content
    // const content = contents?.find(
    //     (content) => content.code === ContentCode.WHEELSBALANCE
    // )
    const { isPending, error, data: content } = ContentDataHook(ContentCode.WHEELSBALANCE)
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    if (error) return 'Wheels Page: An error has occurred: ' + error.message
    return (
        <>
            <SeoTitleAndDesc
                title={content?.title || 'Wheel Balance Weights'}
                seo_title={content?.seo_title}
                seo_desc={content?.seo_desc}
            />
            <BannerSection
                color='#fff'
                title='Wheel Balance Weights'
                background='/images/banner-products.webp'
            />
            <Navigation
                title='Wheel Balance Weights'
                titleLink='/wheel-balance'
                imageUrl='/images/banner-products.webp'
            />
            <div className="wheels-section">
                <div className="wheels-content">
                    <h3>Wheel Balance Weights<span>&nbsp;</span></h3>
                    <div className="wheels-list">
                        {wheels.map((wheel) => (
                            <div key={wheel.id} className='wheels-list-item'>
                                <img src={wheel.image} alt={wheel.title} />
                                <h4>{wheel.title}</h4>
                                <p className='wheels-list-desc' dangerouslySetInnerHTML={{ __html: wheel.description }}></p>
                                <p className='wheels-list-price'>{wheel.price}</p>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            {content && (
                <ServiceSection
                    // title='WHAT WE DO?'
                    // subtitle={content.title}
                    description={content.content}
                    productTypeList={[]}
                />
            )}
        </>
    )
}
