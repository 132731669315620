
import { combineReducers } from 'redux'
import contentReducer from './contentReducer'
import userReducer from './userReducer'

// combine two reducers of content
export const allReducers = combineReducers({
    content: contentReducer,
    user: userReducer,
})

export default allReducers