import React, { useState, useEffect, useRef } from 'react'
import './HeroSection.css'
// import { AiTwotoneShop, AiOutlineRight, AiFillCrown } from 'react-icons/ai'
// import { Link } from 'react-router-dom'
import ModalTires from './ModalTires'
import ModalBrands from './ModalBrands'
import FindTire from './FindTire'
// import { useSelector } from 'react-redux'
// import { useMediaQuery } from 'react-responsive'
// import MyImage from '../../../components/MyImage'

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current()
        }, delay)
        return () => clearInterval(id)
    }, [delay])
}

export default function HeroSectionMobile({
    productsTypeList
}) {
    // const [isMobile, setIsMobile] = useState(window.innerWidth < 960)
    const [tireTypeOpen, setTireTypeOpen] = useState(false)
    const [tireBrandOpen, setTireBrandOpen] = useState(false)
    const [slideIndex, setSlideIndex] = useState(0)
    useInterval(() => {
        if (slideIndex === 2) {
            setSlideIndex(0)
            return
        }
        setSlideIndex(slideIndex + 1)
    }, 5000)

    function plusSlides(n) {
        // setSlideIndex(slideIndex + n)
        if (n === -1 && slideIndex === 0) {
            setSlideIndex(2)
            return
        }
        if (n === 1 && slideIndex === 2) {
            setSlideIndex(0)
            return
        }
        setSlideIndex(slideIndex + n)
    }

    const item = {}
    switch (slideIndex) {
        case 0:
            item.title = 'Tiremaxx is one of the best in Alberta for tire sales!'
            item.background = `url(/images/hero5-mobile1.webp)`
            item.desc = ``
            break
        case 1:
            item.title = 'Tiremax is a one stop shop for all your tire needs!'
            item.background = 'url(/images/hero5-mobile2.webp)'
            item.desc = ``
            break
        case 2:
            item.title = 'Tiremaxx is one of the best in Canada for tire sales!'
            item.background = 'url(/images/hero5-mobile3.webp)'
            item.desc = ``
            break
        default:
            break
    }

    return (
        <>

            <section className='home-hero-section' style={{
                marginTop: 0
            }}>
                <img src="/images/banner_one_stop_shop.webp" width={'100%'} alt="Tiremax is a one stop shop for all your tire needs" />
                <FindTire
                    setTireTypeOpen={setTireTypeOpen}
                    setTireBrandOpen={setTireBrandOpen}
                    extraClass={{
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                />
                <ModalTires
                    tireTypeOpen={tireTypeOpen}
                    setTireTypeOpen={setTireTypeOpen}
                    productsTypeList={productsTypeList}
                />
                <ModalBrands
                    tireBrandOpen={tireBrandOpen}
                    setTireBrandOpen={setTireBrandOpen}
                />
                <div className='slideshow-container fade' style={{ backgroundImage: item?.background }}>
                    <span className="slideshow-prev" onClick={() => plusSlides(-1)}>&#10094;</span>
                    <span className="slideshow-next" onClick={() => plusSlides(1)}>&#10095;</span>
                    <div className="slideshow-dot-content">
                        <span className={`slideshow-dot ${slideIndex === 0 ? 'active' : ''}`} onClick={() => setSlideIndex(0)}></span>
                        <span className={`slideshow-dot ${slideIndex === 1 ? 'active' : ''}`} onClick={() => setSlideIndex(1)}></span>
                        <span className={`slideshow-dot ${slideIndex === 2 ? 'active' : ''}`} onClick={() => setSlideIndex(2)}></span>
                    </div>
                    {/* {slideIndex === 0 &&
                    <div className='slideshow-content-mobile'>
                        <FindTire setTireTypeOpen={setTireTypeOpen} setTireBrandOpen={setTireBrandOpen} />
                    </div>
                } */}
                </div>
            </section>
        </>
    )
}
