import React, { useState } from 'react'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
import { AiFillInfoCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { config } from '@config'
import './User.css'
import { changePassword } from '@services/api.service'
import { useSelector, useDispatch } from 'react-redux'
import { setUser } from '@reducers/userReducer'

export default function Setting() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.userinfo)

    const [formData, setFormData] = useState({})
    const [result, setResult] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setResult('')
        // console.log('formData', formData)
        if (!formData.password) {
            setResult('Old Password is required!')
            return
        }
        if (!formData.new_password) {
            setResult('New Password is required!')
            return
        }
        if (formData.new_password.length < 6) {
            setResult('Password must be at least 6 characters or digits!')
            return
        }
        if (!formData.confirm_password) {
            setResult('Confirm assword is required!')
            return
        }
        if (formData.new_password !== formData.confirm_password) {
            setResult('Password not match!')
            return
        }
        try {
            const result = await changePassword({ ...formData, username: user.username })
            dispatch(setUser(result))
            setFormData({})
            setResult("success")
            setTimeout(() => {
                navigate('/')
            }, 2000)
        } catch (err) {
            setResult(err.data?.message)
        }
    }
    if (user === null) navigate('/login')

    return (
        <>
            <BannerSection
                color='#fff'
                title='Welcome To Tiremaxx'
                background='/images/hero-section2.webp'
            />
            <Navigation
                title='Setting'
                titleLink='/Setting'
                imageUrl='/images/hero-section2.webp'
            />
            <section className="login-section">
                <div className="login-form">
                    <h3>Change Password</h3>
                    <form onSubmit={handleSubmit}>
                        {/* <div className="input-group">
                            <h3>{user.username}</h3>
                        </div> */}
                        <div className="input-group">
                            <label htmlFor="password">Old Password</label>
                            <input className="common-input" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} type="password" name="password" placeholder="Enter your password" required />
                        </div>
                        <div className="input-group">
                            <label htmlFor="new_password">New Password</label>
                            <input className="common-input" vvalue={formData.new_password} onChange={(e) => setFormData({ ...formData, new_password: e.target.value })} type="password" name="new_password" placeholder="Enter your new password" required />
                        </div>
                        <div className="input-group">
                            <label htmlFor="confirm_password">Confirm Password</label>
                            <input className="common-input" value={formData.confirm_password} onChange={(e) => setFormData({ ...formData, confirm_password: e.target.value })} type="password" name="confirm_password" placeholder="Confirm password" required />
                        </div>
                        <div className="input-group">
                            {/* <Link to="/login">login</Link> */}
                            <button type="submit" className="common-button">Submit</button>
                        </div>
                        {result &&
                            <div className={result === 'success' ? 'result-success' : 'result-error'}>
                                <label><AiFillInfoCircle /> {result}</label>
                            </div>
                        }
                    </form>
                </div>
                <div className="login-img">
                    <img width="400" src="/images/login-side.webp" alt="warehouse tires" />
                    <h3>Are You Looking For Tire Shops In Alberta?<br />
                        Contact Tiremaxx Today!</h3>
                    <h4>Phone: <a href={`tel:${config.phone}`}>{config.phone}</a></h4>
                    <h4>Email: <a href={`mailto:${config.email}`}>{config.email}</a></h4>
                </div>
            </section>
        </>
    )
}
