import './QuestionSection.css'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import {
    getQuestionList,
} from '../services/api.service'

export default function QuestionSection({
    title,
    // questionList
}) {

    const {
        isPending,
        error,
        data: faqList,
    } = useQuery({
        queryKey: ['faqList'],
        queryFn: getQuestionList,
    })

    // Show a loading spinner while the FAQ list is being fetched
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    // If there was an error while fetching return an error message
    if (error) return 'About Page: An error has occurred: ' + error.message

    function onClick(e) {
        const span = e.target.querySelector('span')
        e.target.querySelector('p').style.display = span.innerHTML === '+' ? 'block' : 'none'
        span.innerHTML = span.innerHTML === '+' ? '-' : '+'
    }
    return (
        <div className="common-title-row about-question">
            <h3>{title}<span>&nbsp;</span></h3>
            {faqList?.data && (
                <div className="list">
                    <ul>
                        {faqList?.data?.map((item, index) => (
                            <li key={index} onClick={(e) => onClick(e)}>
                                <span>+</span>{item?.title}
                                <p className='desc-content ql-editor' dangerouslySetInnerHTML={{ __html: item?.content }}></p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}
