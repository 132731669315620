import './New.css'
import React, { useEffect } from 'react'
import BannerSection from '@components/BannerSection'
import { useLocation } from 'react-router-dom'
import Navigation from '@components/Navigation'
import NewSide from './components/NewSide'
import NewItem from './components/NewItem'
import NewItemCard from './components/NewItemCard'
import ReactPaginate from 'react-paginate'
import { useSelector, useDispatch } from 'react-redux'
import { getNewList, getNewContent } from '@services/api.service'
import { setNewsList, setCurrentNews, setCurrentNewsId, setNewsFilterPage, setGotoElement } from '@reducers/contentReducer'
import NewView from './components/NewView'
import { useParams } from 'react-router-dom'
import helper from '@utils/helper'

export default function New() {

    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const newsFilter = useSelector((state) => state.content.newsFilter)
    const newsList = useSelector((state) => state.content.newsList)
    const currentNews = useSelector((state) => state.content.currentNews)
    const currentNewsId = useSelector((state) => state.content.currentNewsId)

    const { title } = useParams()

    // if (!title) {
    //     dispatch(setCurrentNews(null))
    // }
    useEffect(() => {
        async function fetchData() {
            try {

                const result = await getNewList({ ...newsFilter, pathname: pathname })
                // console.log('result', result)
                if (result.data.length > 0 && !currentNewsId) {
                    // console.log('title', title)
                    result.data.forEach(item => {
                        if (helper.formatTitleForLink(item.title) === title) {
                            dispatch(setCurrentNewsId(item.id))
                            return
                        }
                    })
                }
                dispatch(setNewsList(result))
            } catch (err) {
                console.log(err.data?.message || 'get news list error')
            }
        }
        fetchData()
    }, [newsFilter, dispatch, title, currentNewsId, pathname])

    useEffect(() => {
        if (!currentNewsId)
            return
        const fetchData = async () => {
            try {
                const result = await getNewContent(currentNewsId, { pathname: pathname })
                dispatch(setCurrentNews(result.data))
            } catch (err) {
                console.log(err.data?.message || 'get news content error')
            }
        }
        fetchData()
    }, [currentNewsId, dispatch, pathname])

    // const handleClickNews = async (id) => {
    //     try {
    //         const result = await getNewContent(id)
    //         dispatch(setCurrentNews(result.data))
    //     } catch (err) {
    //         console.log(err.data?.message || 'get news content error')
    //     }
    // }

    const handlePageClick = (e) => {
        const selected = e.selected
        dispatch(setNewsFilterPage(selected + 1))
        dispatch(setGotoElement('new-list'))
    }

    return (
        <>
            <BannerSection
                color='#fff'
                title=''
                background='/images/news-banner.webp'
            />
            <Navigation
                title='Articles'
                titleLink='/articles'
                subtitle={currentNews?.title}
                imageUrl={currentNews?.picture ? currentNews?.picture : '/images/news-banner.webp'}
            />
            {currentNews && title ? (
                <NewView
                    currentNews={currentNews}
                    // handleClickNews={handleClickNews}
                    newsList={newsList.data}
                />
            ) : (
                <div className="new-content">
                    <div className="new-list" id='new-list'>
                        <div className="column-header">
                            <h3>News List</h3>
                        </div>
                        <div className='new-content-list-new' style={{
                            flexDirection: 'row'
                        }}>
                            {newsList.data && newsList.data.map((item, index) => (
                                <NewItemCard
                                    key={index}
                                    index={index}
                                    currentRow={item}
                                // onClick={() => handleClickNews(item.id)}
                                />
                            ))}
                        </div>
                        {newsList?.totalPage > 0 && (
                            <div className="pagination">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    pageRangeDisplayed={4}
                                    onPageChange={handlePageClick}
                                    pageCount={newsList.totalPage}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    forcePage={newsFilter.current - 1}
                                />
                            </div>
                        )}
                    </div>
                    <NewSide />
                </div>
            )}
        </>
    )
}
