import './BannerSection.css'
import React from 'react'

export default function BannerSection({
    color, title, description, background, height = '250px', extraClass
}) {
    const styles = {
        backgroundImage: `url('${background}')`,
        color: color,
        textAlign: 'center',
        height: height,
        ...extraClass
    }
    return (
        <section className="banner-wrapper" style={{ height: height }}>
            <div className="banner-section" style={styles}>
                <h3>{title}</h3>
                {description && <h4>{description}</h4>}
            </div>
        </section>
    )
}
