import { useQuery } from '@tanstack/react-query'
import { getContent } from '@services/api.service'

export default function ContentDataHook(code) {

  const {
    isPending,
    error,
    data,
  } = useQuery({
    queryKey: ['content', code],
    queryFn: () => getContent(code),
  })

  // if (isPending) return <Loading />
  // console.log('about', data)
  // if (error) return 'An error has occurred: ' + error.message

  return { isPending, error, data: data?.data }
}
