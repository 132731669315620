import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { config } from '../Config'
import { addView } from '../services/api.service'
import { useSelector, useDispatch } from 'react-redux'
import { setGotoElement } from '@reducers/contentReducer'

export default function ScrollToTop() {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const gotoElement = useSelector((state) => state.content.gotoElement)
  // useEffect when gotoElement changed
  useEffect(() => {
    if (!gotoElement) {
      // window.scrollTo(0, 0)
      return
    }
    const element = document.getElementById(gotoElement)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
    dispatch(setGotoElement(null))
  }, [gotoElement, dispatch])

  useEffect(() => {
    const fetchAddView = async (pathname) => {
      await addView({ pathname: pathname })
    }
    fetchAddView(pathname)
    window.scrollTo(0, 0)
  }, [pathname])

  let host = window.location.href
  console.log('pathname', pathname)
  console.log('window.location', window.location)

  return (
    <HelmetProvider>
      {!pathname.includes('product-detail') &&
        !pathname.includes('new-detail') && (
          <Helmet>
            <link rel="canonical" href={host} />
            <link rel="alternate" href={host} hreflang="en-ca" />
            <title>
              {pathname !== '/'
                ? `${
                    pathname.slice(1).charAt(0).toUpperCase() +
                    pathname.slice(2)
                  } - ${config.appName}`
                : config.appName}
            </title>
          </Helmet>
        )}
    </HelmetProvider>
  )
}
