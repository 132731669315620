import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

export default function MyImage({ src, alt, width, height, style, className }) {
    return <LazyLoadImage
        alt={alt}
        effect="blur"
        className={className}
        src={src}
        width={width ? width : '100%'}
        height={height ? height : '100%'}
        style={style}
    />
}