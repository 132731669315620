import './Contact.css'
import React from 'react'
// import { useDropzone } from 'react-dropzone'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
// import { useSelector } from 'react-redux'
import { ContentCode } from '@data/ContentData'
import { Link } from 'react-router-dom'
import { List } from 'antd'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import ContentDataHook from '@hooks/ContentData.hook'
import MessageForm from '@components/MessageForm'

const sideList = [
    {
        title: 'About Us',
        link: '/about'
    },
    {
        title: 'Partner Brands',
        link: '/partner-brands'
    },
    // {
    //     title: 'Contact Us',
    //     link: '/contact'
    // },
]

export default function Contact() {
    // get contents from redux
    // const contents = useSelector((state) => state.content.contents)
    // find about and mission content
    // const content = contents?.find(content => content.code === ContentCode.CONTACT)

    const { isPending, error, data: content } = ContentDataHook(ContentCode.CONTACT)
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    if (error) return 'Contact Page: An error has occurred: ' + error.message

    // const onDrop = useCallback(acceptedFiles => {
    //     // Do something with the files
    //     console.log(acceptedFiles)
    // }, [])
    // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <>
            <SeoTitleAndDesc
                title={content?.title || 'Contact'}
                seo_title={content?.seo_title}
                seo_desc={content?.seo_desc}
            />
            <BannerSection
                color='#fff'
                title='Contact Us'
                background='/images/banner-contact.webp'
            />
            <Navigation
                title='Contact Us'
                titleLink='/contact'
                imageUrl='/images/banner-contact.webp'
            />
            {content &&
                <div className='contact-wrapper'>
                    <div className='contact-left'>
                        <List
                            header={<h3>Quick Links</h3>}
                            bordered
                            size='large'
                            dataSource={sideList}
                            renderItem={(item) => <List.Item><Link to={item.link}>{item.title}</Link></List.Item>}
                        />
                    </div>
                    <div className='contact-content'>
                        <h3>{content.title}</h3>
                        <p className='desc-content ql-editor' dangerouslySetInnerHTML={{ __html: content.content }}></p>
                        <br />
                        <hr />
                        <h3>Message Form</h3>
                        <MessageForm />
                    </div>

                </div>
            }
            {/* <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div> */}
        </>
    )
}
