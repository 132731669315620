const BrandsData = {
    brands: [
        {
            id: 5,
            title: 'Firemax',
            short: 'firemax',
            image: '/images/brands/FM805 P3 with watermark.webp',
            logo: '/images/brands/logo_firemax.webp',
            desc: 'Firemax tires offer premium tread for optimum performance. With a quality material, these tires are highly sought after. Firemax produces over 12 million tires and exports to more than 100 countries around the world annually to provide consumers with competitive, quality tires. Through a portfolio of well-priced products, streamlined manufacturing, and extensive research, Firemax has been able to thrive and continues to expand across the globe.',
            seo_title: 'Buy Firemax Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Firemax tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Firemax Tires for Sale in Calgary'
        },
        {
            id: 9,
            title: 'Kpatos',
            short: 'kpatos',
            image: '/images/brands/kpatos FM501 AT with watermark.webp',
            logo: '/images/brands/logo_kpatos.webp',
            desc: 'A sister brand of Firemax, Kpatos focuses on performance tires in milder weathers, such as all-season and touring. Like Firemax, Kpatos utilizes modern technology and premium materials to create their well-priced and well-performing tires, especially for all-terrain and off-roading.',
            seo_title: 'Buy Kpatos Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Kpatos tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Kpatos Tires for Sale in Calgary'
        },
        {
            id: 0,
            title: 'Arivo',
            short: 'arivo',
            image: '/images/brands/A702 - A-Plus with watermark.webp',
            logo: '/images/brands/logo_arivo.webp',
            desc: 'Arivo is a classic word for arrival, it means “Wherever you go, our tires will take you to your destination.” The design and originality of the brand is from the United Kingdom. Arivo tires are manufactured for Passenger, SUV, Light Truck and Commercial vehicles. All tires have strict quality control and have obtained ISO9001 & ISO16949 Quality Management System Certification. Product Liability is underwritten by Jordan & Jordan Insurance Group (USA).',
            seo_title: 'Buy Arivo Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Arivo tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Arivo Tires for Sale in Calgary'
        },
        {
            id: 1,
            title: 'A-Plus',
            short: 'a-plus',
            image: '/images/brands/A702 - A-Plus with watermark.webp',
            logo: '/images/brands/logo_aplus.webp',
            desc: 'A-Plus tires offer excellent grip performance as the tires are strategically engineered with rubber compounds that are wearable yet flexible. No matter the tire type, their intricate tread pattern designs feature a proven modular tread block geometry which helps with traction on most terrains. With superior water and debris dispersion, the tires are equipped to handle all sorts of driving conditions.',
            seo_title: 'Buy A-Plus Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop A-Plus tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best A-Plus Tires for Sale in Calgary'
        },
        {
            id: 2,
            title: 'Anchee',
            short: 'anchee',
            image: '/images/brands/AC818 - Anchee with watermark.webp',
            logo: '/images/brands/logo_anchee.webp',
            desc: 'Anchee specializes in making speed-performance tires, which is why you will see we carry this line of products in all-season and summer tires. The rubber on these tires is generally designed to make maximum contact with the road surface. Anchee tires provide great maneuverability and control while tread patterns help in reducing noise. The tread is also recognized for lowering rolling resistance.',
            seo_title: 'Buy Anchee Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Anchee tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Anchee Tires for Sale in Calgary'
        },
        {
            id: 8,
            title: 'Aptany',
            short: 'aptany',
            image: '/images/brands/RW631 P2 - Aptany with watermark.webp',
            logo: '/images/brands/logo_aptany.webp',
            desc: 'A sub-division of Wanli, Aptany focuses on high-performance, all-season, and touring tires, while producing several competitive winter tires. The tires use a high-grade rubber compound to help ensure safety and handling ability. Along with impressive traction capability, Aptany is able to provide passengers with a quiet and comfortable ride.',
            seo_title: 'Buy Aptany Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Aptany tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Aptany Tires for Sale in Calgary'
        },
        {
            id: 4,
            title: 'Compasal',
            short: 'compasal',
            image: '/images/brands/Compasal-Crosstop-4S-1 with watermark.webp',
            logo: '/images/brands/logo_compasal.webp',
            desc: 'Compasal offers a wide array of products to be able to meet the needs of consumers all over the globe. The versatility of their product line enables them to be a suitable vendor for markets in North America. Compasal tires offer a pattern design that has both appearance and practicality. These tires present adequate performance at an attractive price-point.',
            seo_title: 'Buy Compasal Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Compasal tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Compasal Tires for Sale in Calgary'
        },
        {
            id: 3,
            title: 'Lanvigator',
            short: 'lanvigator',
            image: '/images/brands/CATCHFORS AT P3 - Lanvigator with watermark.webp',
            logo: '/images/brands/logo_lanvigator.webp',
            desc: 'Lanvigator provides a product that has been rated a high price-performance ratio by many consumers. Lanvigator is an established tire manufacturer that has been providing drivers with reliable tires for over 20 years. For customers looking for a competitively priced tire that lives up to the standard, this product is an excellent choice.',
            seo_title: 'Buy Lanvigator Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Lanvigator tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Lanvigator Tires for Sale in Calgary'
        },
        {
            id: 6,
            title: 'Royal Black',
            short: 'royal-black',
            image: '/images/brands/ROYAL WINTER P1 with watermark.webp',
            logo: '/images/brands/logo_royal.webp',
            desc: 'The Royal Black tires offer a variety of tread patterns. Whether all-seasons, all-weathers or winter tires, Royal Black is able to provide a product that can cater to the different seasons of the year. With a selection of specialized tread designs for each tire type, Royal Black is a great option for customers looking for a product more specific to their needs.',
            seo_title: 'Buy Royal Black Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Royal Black tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Royal Black Tires for Sale in Calgary'
        },
        {
            id: 10,
            title: 'Sunny',
            short: 'sunny',
            image: '/images/brands/NW312 - Sunny with watermark.webp',
            logo: '/images/brands/logo_sunny.webp',
            desc: 'Sunny tires are made in all different tire types to cater to the different seasons of the year. Their all season tires have sharp handling while giving the sporty look and feel. The tires are designed with fuel economy taken into consideration, and provide a low rolling resistance. Sunny’s winter tires have premium-grade rubber with aggressive tread patterns to handle the toughest of winter conditions.',
            seo_title: 'Buy Sunny Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Sunny tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Sunny Tires for Sale in Calgary'
        },
        {
            id: 7,
            title: 'Wanli',
            short: 'wanli',
            image: '/images/brands/RU028 P2 - Wanli with watermark.webp',
            logo: '/images/brands/logo_wanli.webp',
            desc: 'A major tire manufacturing company based in China, Wanli produces over 16 million tires annually. With TUV Reinhard Certification, Wanli is a renowned manufacturer that provides excellent quality at affordable prices. Wanli is part of the Guanzhou Vanlead Group which also produces tires under Aptany and Sunny.',
            seo_title: 'Buy Wanli Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Wanli tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Wanli Tires for Sale in Calgary'
        },
        {
            id: 11,
            title: 'Road Cruza',
            short: 'road-cruza',
            image: '/images/brands/road_cruza with watermark.webp',
            logo: '/images/brands/logo_road_cruza.webp',
            desc: `Road Cruza tires represent that perfect balance between city tire and adventure tire. Road Cruza design closely resembles that of BFGoodrich KO2, a highly sought after tire. With a little greater distance between tread blocks, Road Cruza arguably expels mud and dirt even better. Ensuring exceptional traction in challenging terrains, both off-road and paved surfaces. The sidewall design prioritizes tire safety and aesthetics. Road Cruza, where your adventure meets the road.`,
            seo_title: 'Buy Road Cruza Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Road Cruza tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Road Cruza Tires for Sale in Calgary'
        },
        {
            id: 12,
            title: 'Annaite',
            short: 'annaite',
            image: '/images/brands/annaite_watermark.webp',
            logo: '/images/brands/logo_annaite.webp',
            desc: `Founded in 1994, Annaite is among the top 75 tire manufacturers in the world. Parent company 'Xingyuan Tires Group' has assets totalling over $1.2 billion and employing over 5,000 workers and engineers. It has an annual output of 6 million sets of tires across their seven subsidiary brands, such as Hilo, Annaite, and Anchee,  with over 80 tire sizes and 130 tread patterns. Annaite specializes in light-truck and semi-truck tires, providing quality and safety to millions of people around the world at an economic price.`,
            seo_title: 'Buy Annaite Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Annaite tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Annaite Tires for Sale in Calgary'
        },
        {
            id: 13,
            title: 'Hilo',
            short: 'hilo',
            image: '/images/brands/hilo_watermark.webp',
            logo: '/images/brands/logo_hilo.webp',
            desc: `Solidly established as one of the world's top quality tire manufacturers, our state-of-the-art factory produces a full line of Passenger All-Season, Off-Road, Winter and Commercial tires for use in all kinds of road and weather conditions, all of which have received the most demanding Quality Certifications in the world. Made in the same factory as Yokohama, a very prominent brand name in the tire industry, Hilo tires provide great maneuverability and control while tread patterns help in reducing noise. The tread is also recognized for lowering rolling resistance.`,
            seo_title: 'Buy Hilo Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Hilo tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Hilo Tires for Sale in Calgary'
        },
        {
            id: 14,
            title: 'Rapid',
            short: 'rapid',
            image: '/images/brands/rapid_watermark.webp',
            logo: '/images/brands/logo_rapid.webp',
            desc: `RAPID tyres are crafted utilizing state-of-the-art production techniques, premium quality materials, and undergo rigorous testing and inspection to ensure they adhere to the highest standards. They are compliant with all European Tyre legislations, delivering excellent performance at high speeds, exceptional grip in both wet and dry conditions, and ensuring safe handling and cornering. The strategic goal of Rapid Tyres is to prioritise product quality, with an ambition to ascend as a prominent figure in the tyre industry. Produced by the Shengtai Group, RAPID tyres offer unmatched performance at an affordable cost. The production facilities are internationally certified for quality assurance, guaranteeing that RAPID tyres meet stringent quality standards, instilling confidence in their product quality.`,
            seo_title: 'Buy Rapid Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Rapid tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Rapid Tires for Sale in Calgary'
        },
        {
            id: 15,
            title: 'Mileking',
            short: 'mileking',
            image: '/images/brands/mileking_watermark.webp',
            logo: '/images/brands/logo_mileking.webp',
            desc: `Mileking is a tire manufacturing company situated in Qingdao, China. This company specializes in manufacturing passenger, SUV and light truck tires for the market. Their products offer a safer driving experience, better controllability and excellent traction at affordable prices. MILEKING, Shandong Acmex Rubber Group Co. Ltd is a professional integrated enterprise, setting tires' production, sales, and technology R & D. This gives their tires finely tuned tread patterns for improved steering response with high silica content providing confident wet and dry performance.`,
            seo_title: 'Buy Mileking Tires in Calgary | Best Prices & Deals',
            seo_desc: 'Shop Mileking tires at Tiremaxx. Find discounted, cheap tires. Enjoy high-quality products at low prices. Special offers available now.',
            seo_h1: 'Best Mileking Tires for Sale in Calgary'
        }
    ]
}
// BrandsData.brands.sort((a, b) => a.title.localeCompare(b.title))
export default BrandsData