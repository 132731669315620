import React from 'react'
import PartnerBrandsSection from './components/PartnerBrandsSection'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
import BrandsData from '@data/BrandsData'
// import { useSelector } from 'react-redux'
import { ContentCode } from '@data/ContentData'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import ContentDataHook from '@hooks/ContentData.hook'

export default function Partner() {
    // get contents from redux
    // const contents = useSelector((state) => state.content.contents)
    // find about and mission content
    // const content = contents?.find(
    //     (content) => content.code === ContentCode.BRANDS
    // )

    const { isPending, error, data: content } = ContentDataHook(ContentCode.BRANDS)
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    if (error) return 'Partner Page: An error has occurred: ' + error.message

    const brandList = BrandsData.brands
    // brandList.sort(function (a, b) {
    //     if (a.title < b.title) { return -1 }
    //     if (a.title > b.title) { return 1 }
    //     return 0
    // })


    return (
        <>
            <SeoTitleAndDesc
                title={content?.title || 'Our Partner Brands'}
                seo_title={content?.seo_title}
                seo_desc={content?.seo_desc}
            />
            <BannerSection
                color='#fff'
                title='Our Partner Brands'
                background='/images/banner-partner.webp'
            />
            <Navigation
                title='Our Partner Brands'
                titleLink='/partner-brands'
                imageUrl='/images/banner-partner.webp'
            />
            {content && (
                <PartnerBrandsSection
                    key='product-section'
                    title={content.title}
                    // subtitle="Tiremaxx Corp's Gallery"
                    description={content.content}
                    brandsList={brandList}
                />
            )}
        </>
    )
}
