const ContentData = {

}

// the code of contents, it is used to identify the content
export const ContentCode = {
    ABOUT: 'About',
    CONTACT: 'Contact',
    SERVICES: 'Services',
    CONSENT: 'Consent',
    REFUND: 'refund',
    STORE: 'store',
    PRIVACY: 'privacy',
    DELIVERY: 'Delivery',
    PRICING: 'pricing',
    BRANDS: 'Brands',
    WHEELS: 'wheels',
    SHIPPING: 'shipping',
    SHIPPING_AREA: 'shipping-area',
    WHEELSBALANCE: 'wheel-balance',
}

export default ContentData