import React from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { config } from '@config'

export default function SeoTitleAndDesc({
    title,
    seo_title,
    seo_desc
}) {
    let seoTitle = config.appName
    if (seo_title)
        seoTitle = `${seo_title}`

    console.log('seoTitle', seoTitle)

    if (seo_desc)
        document.querySelector('meta[name="description"]').setAttribute('content', seo_desc)

    return (
        <HelmetProvider>
            <Helmet>
                <title>{seoTitle}</title>
                {/* {seo_title && <meta name="title" content={seo_title} />} */}
                {/* {seo_desc && <meta name="description" content={seo_desc} />} */}
            </Helmet>
        </HelmetProvider>
    )
}
