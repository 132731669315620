import React from 'react'

export default function ProductNote() {
    return (
        <div className='product-detail-note'>
            <p style={{ color: '#ff0000' }}>For shipping information please inquire.</p>
            <p>
                <strong>For retail:</strong><br />
                Price does not include: tire recycling fee <font color='red'>($5 per tire)</font>; GST <font color='red'>(5%)</font>; credit card fee <font color='red'>(2%)</font>; non-set purchase <font color='red'>($5 per tire)</font>
                <br />
                Certain sizes cannot break set of four.<br />

                <br />
                <strong>For shop:</strong><br />
                Price does not include: tire recycling fee <font color='red'>($5 per tire)</font>; delivery fee <font color='red'>(varies by distance)</font>; credit card fee <font color='red'>(2%)</font>
                <br />
                Certain sizes cannot break set of four.
            </p>
        </div>
    )
}
