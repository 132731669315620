import './Navigation.css'
import React from 'react'
import { Link } from 'react-router-dom'
import { AiFillHome } from 'react-icons/ai'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import ProductSeoData from '@data/ProductSeoData'

export default function Navigation({
  title,
  titleLink,
  subtitle,
  subtitleLink,
  lasttitle,
  lasttitleLink,
  imageUrl,
  pageType
}) {
  const breadcrumbSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://www.tiremaxx.ca/'
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: title,
        item: 'https://www.tiremaxx.ca' + titleLink
      }
    ]
  }
  if (subtitle) {
    breadcrumbSchema.itemListElement.push({
      '@type': 'ListItem',
      position: 3,
      name: subtitle,
      item: subtitleLink
        ? 'https://www.tiremaxx.ca' + subtitleLink
        : window.location.href
    })
  }
  if (lasttitle) {
    breadcrumbSchema.itemListElement.push({
      '@type': 'ListItem',
      position: 4,
      name: lasttitle,
      item: lasttitleLink
        ? 'https://www.tiremaxx.ca' + lasttitleLink
        : window.location.href
    })
  }
  let description = lasttitle ? lasttitle : subtitle ? subtitle : title
  let og_type = 'website'
  if (title === 'Tires') {
    og_type = 'product'
  } else if (title === 'Articles') {
    og_type = 'article'
  }
  if (imageUrl.indexOf('https') === -1) {
    imageUrl = 'https://www.tiremaxx.ca' + imageUrl
  }
  if (pageType === 'product_list' && !lasttitle) {
    const find = ProductSeoData.find((item) => item.page === title)
    if (find) {
      description = find.desc
      imageUrl = find.image
    }
  }
  if (pageType === 'product_detail' && subtitle) {
    // replace all space
    const idName = subtitle.toLowerCase().replace(/ /g, '')
    const find = ProductSeoData.find((item) => item.id === idName)
    if (find) {
      description = `${lasttitle} - ${subtitle} - ${find.desc}`
    }
  }
  let metaTitle = lasttitle ? lasttitle : subtitle ? subtitle : title
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbSchema)}
          </script>
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content={og_type} />
          <meta property="og:locale" content="en_CA" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={imageUrl} />
        </Helmet>
      </HelmetProvider>
      <div className="navigation">
        <ul>
          <li>
            <AiFillHome />
          </li>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>/</li>
          <li>
            <Link to={titleLink}>{title}</Link>
          </li>
          {subtitle && (
            <>
              <li>/</li>
              <li>
                <Link to={subtitleLink || window.location.href}>
                  {subtitle}
                </Link>
              </li>
            </>
          )}
          {lasttitle && (
            <>
              <li>/</li>
              <li>
                <Link to={lasttitleLink || window.location.href}>
                  {lasttitle}
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  )
}
