export const StatusTypeCode = {
    DEFAULT: 0,
    ON_SALE: 1,
    ON_CLEARANCE: 2,
    PREMIUM: 3
}

export const ProductTypes = [
    {
        id: 5,
        title: 'Summer Tires',
        short: 'summertires'
    },
    {
        id: 8,
        title: 'Winter Tires',
        short: 'wintertires'
    },
    {
        id: 10,
        title: 'All Season Tires',
        short: 'allseasontires'
    },
    {
        id: 11,
        title: 'Mud Terrain Tires',
        short: 'mudterraintires'
    },
    {
        id: 12,
        title: 'All Terrain Tires',
        short: 'allterraintires'
    },
    {
        id: 13,
        title: 'All Weather Tires',
        short: 'allweathertires'
    },
    {
        id: 18,
        title: 'Tire Bags',
        short: 'tirebags'
    }
]