import './PartnerBrandsSection.css'
import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setFilterBrands } from '@reducers/contentReducer'

export default function PartnerBrandsSection({
    title,
    description,
    brandsList
}) {
    const dispatch = useDispatch()
    return (
        <section className="partner-brands-section">
            <div className="partner-header">
                <h4>{title}</h4>
                {/* <h3>{subtitle}</h3> */}
                {/* <p dangerouslySetInnerHTML={{ __html: description }}></p> */}
            </div>

            <div className="partner-top-images">
                {brandsList && brandsList.map((item, index) => (
                    <div key={index} className='partner-top-image-item'>
                        <Link key={item.id} to={`/tires/brand/${item.short}`} onClick={() => {
                            dispatch(setFilterBrands({ brand: item.title, action: 'set' }))
                        }}>
                            <img className='partner-top-image' src={item.logo} alt={item.title} />
                        </Link>
                        <h4 className='partner-top-title'>{item.title}</h4>
                        <p className='partner-top-desc'>{item.desc}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}
