import React, { useState, useEffect, useRef } from 'react'
import './HeroSection.css'
// import { AiTwotoneShop, AiOutlineRight, AiFillCrown } from 'react-icons/ai'
// import { Link } from 'react-router-dom'
import ModalTires from './ModalTires'
import ModalBrands from './ModalBrands'
import FindTire from './FindTire'
import { useSelector } from 'react-redux'
// import { useMediaQuery } from 'react-responsive'
// import MyImage from '../../../components/MyImage'

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current()
        }, delay)
        return () => clearInterval(id)
    }, [delay])
}

export default function HeroSection({
    productsTypeList
}) {
    const isMobile = useSelector((state) => state.content.isMobile)
    // const [isMobile, setIsMobile] = useState(window.innerWidth < 960)
    const [tireTypeOpen, setTireTypeOpen] = useState(false)
    const [tireBrandOpen, setTireBrandOpen] = useState(false)
    const [slideIndex, setSlideIndex] = useState(0)
    useInterval(() => {
        if (isMobile) {
            setSlideIndex(0)
            return
        }
        if (slideIndex === 4) {
            setSlideIndex(0)
            return
        }
        setSlideIndex(slideIndex + 1)
    }, 5000)

    function plusSlides(n) {
        // setSlideIndex(slideIndex + n)
        if (n === -1 && slideIndex === 0) {
            setSlideIndex(4)
            return
        }
        if (n === 1 && slideIndex === 4) {
            setSlideIndex(0)
            return
        }
        setSlideIndex(slideIndex + n)
    }

    // const handleMediaQueryChange = (matches) => {
    //     // matches will be true or false based on the value for the media query
    //     console.log('matches', matches)
    //     setIsMobile(matches)
    // }

    // useMediaQuery(
    //     { maxWidth: 960 }, undefined, handleMediaQueryChange
    // )

    // const background = slideIndex === 1
    //     ? `url(/images/hero2.webp)`
    //     : `url(/images/hero1${isMobile ? '_mobile' : ''}.webp)`
    const item = {}
    switch (slideIndex) {
        case 0:
            item.title = ''
            item.background = 'url(/images/hero5.webp)'
            item.desc = ``
            break
        case 1:
            item.title = 'Tires for Sale in Red Deer, Alberta & Edmonton Wide'
            item.background = `url(/images/hero1${isMobile ? '_mobile' : ''}.webp)`
            item.desc = `Tiremaxx is an exceptional platform for anyone looking to purchase tires in Alberta. The website's user interface is simple, yet elegant, making it easy for customers to navigate and find what they need quickly. You can check out the tires available in your size, and give us a call to book delivery.
            We have an extensive selection of amazingly priced tires from various brands. Whether you are looking for winter or all-season tires, you can be sure that you will find them on our site.
            Another great thing about us is is competitive pricing. You can always count on finding the best deals on quality tires at our store. We also offer promotions throughout the year.
            Our team responds promptly to customer queries via email or phone calls ensuring that every customer feels valued and appreciated no matter how small or big their order may be.
            If you're planning to buy new tires soon Alberta - consider contacting us!`
            break
        case 2:
            item.title = 'Buy Used Tires in Calgary and Airdrie from our Web Shop'
            item.background = 'url(/images/hero2.webp)'
            item.desc = `Are you in the market for new tires but don't want to break the bank? Look no further than Tiremaxx! Located in Calgary, Alberta, Tiremaxx offers some of the best deals on tires around. 
            Tiremaxx has been providing top-quality tires to the people of Calgary and Alberta Wide for years. Our reputation as a reliable and affordable tire retailer is well-earned, thanks in part to our user-friendly website that makes searching for tires a breeze.
            One of the things that set us apart from other tire retailers is its commitment to customer satisfaction. We take pride in offering exceptional customer service and are always available to answer any questions you may have about your purchase.
            Whether you're looking for all-season, winter or summer tires, Tiremaxx has got you covered. With a wide selection of brands and models to choose from, finding the perfect set of tires for your vehicle has never been easier!
            Tire shopping can be overwhelming at times, but with Tiremaxx's easy-to-use website and knowledgeable staff members, purchasing new tires doesn't have to be stressful!`
            break
        case 3:
            item.title = 'Tiremaxx is one of the best in Alberta for tire sales!'
            item.background = 'url(/images/hero3.webp)'
            item.desc = `We are amongst the best tire shop offering tires for sale in Calgary & Airdrie. Our storeprovide used tires in Red Deer, Alberta & Edmonton.`
            break
        case 4:
            item.title = 'Premium Road Cruza All-Terrain Tires Sales Event!'
            item.background = 'url(/images/hero4.webp)'
            item.desc = `Premium Road Cruza All-Terrain Tires Sales Event!`
            break
        default:
            break
    }

    return (
        <section className='home-hero-section'>
            <ModalTires
                tireTypeOpen={tireTypeOpen}
                setTireTypeOpen={setTireTypeOpen}
                productsTypeList={productsTypeList}
            />
            <ModalBrands
                tireBrandOpen={tireBrandOpen}
                setTireBrandOpen={setTireBrandOpen}
            />
            <div className='slideshow-container fade' style={{ backgroundImage: item?.background }}>
                {!isMobile && <>
                    <span className="slideshow-prev" onClick={() => plusSlides(-1)}>&#10094;</span>
                    <span className="slideshow-next" onClick={() => plusSlides(1)}>&#10095;</span>
                    <div className="slideshow-dot-content">
                        <span className={`slideshow-dot ${slideIndex === 0 ? 'active' : ''}`} onClick={() => setSlideIndex(0)}></span>
                        <span className={`slideshow-dot ${slideIndex === 1 ? 'active' : ''}`} onClick={() => setSlideIndex(1)}></span>
                        <span className={`slideshow-dot ${slideIndex === 2 ? 'active' : ''}`} onClick={() => setSlideIndex(2)}></span>
                        <span className={`slideshow-dot ${slideIndex === 3 ? 'active' : ''}`} onClick={() => setSlideIndex(3)}></span>
                        <span className={`slideshow-dot ${slideIndex === 4 ? 'active' : ''}`} onClick={() => setSlideIndex(4)}></span>
                    </div>
                </>}
                <div className='slideshow-content'>
                    <FindTire setTireTypeOpen={setTireTypeOpen} setTireBrandOpen={setTireBrandOpen} />
                    {item?.title &&
                        <a className='slideshow-content-desc' href="https://www.tiremaxx.ca/articles/why-choose-tiremaxx" alt="why choose tiremaxx" target="_blank" rel="noindex nofollow noopener noreferrer">
                            {item?.title}
                        </a>
                    }
                    {item?.desc &&
                        <p>
                            {item?.desc}
                        </p>
                    }
                </div>
            </div>
        </section>
    )
}
