import React from 'react';
import {AiOutlineClose} from 'react-icons/ai'
import './PolicyModal.css';

export default function PolicyModal({
    show,
    setShow,
    policy,
}) {

    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <>
            {policy && (
                <div className={showHideClassName}>
                    <section className="modal-main">
                        <h3 className="modal-title">{policy.title}</h3>
                        <AiOutlineClose className='btn-close' onClick={()=>setShow(false)}/>
                        <div className="modal-content">
                            <p className='desc-content ql-editor' dangerouslySetInnerHTML={{ __html: policy.content }}></p>
                        </div>
                        <div className='modal-btns'>
                            <button className="common-button btn-agree" type="button" onClick={()=>{
                                setShow(false);
                            }}>
                                Close
                            </button>
                        </div>
                    </section>
                </div>
            )}
        </>
    )
}