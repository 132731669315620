import './NewSide.css'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setNewsFilterTypes } from '@reducers/contentReducer'

export default function NewSide() {

    const newsTypeList = useSelector((state) => state.content.newsTypeList)
    const newsFilter = useSelector((state) => state.content.newsFilter)
    const dispatch = useDispatch()

    const onClickTypes = (e) => {
        const value = e.target.value
        if (value === 'all') {
            dispatch(setNewsFilterTypes({ type: [], action: 'all' }))
            return
        }
        dispatch(setNewsFilterTypes({ type: Number(value), action: e.target.checked ? 'add' : 'remove' }))
    }

    return (
        <section className="new-side">
            <div className="new-filter-item">
                <h3>Filter
                    <span>
                        <label>All
                            <input type="checkbox" value="all"
                                className="input-radio-checkbox"
                                checked={newsFilter.types.length === 0}
                                onChange={onClickTypes} />
                        </label>
                    </span>
                </h3>
                <div className="new-filter-types">
                    {newsTypeList && newsTypeList.map((item, key) => (
                        <div key={key} className="new-type-title">
                            <label>
                                <input type="checkbox" value={item.id}
                                    className="new-input-radio-checkbox"
                                    checked={newsFilter.types.includes(item.id)}
                                    onChange={onClickTypes} /> {item.title} ({item.count})
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}