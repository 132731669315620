import React, { useState } from 'react'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillInfoCircle } from 'react-icons/ai'
import { config } from '@config'
import './User.css'
import { register } from '@services/api.service'
// import { useSelector } from 'react-redux'
import { ContentCode } from '@data/ContentData'
import { Modal, Checkbox } from 'antd'
import ContentDataHook from '@hooks/ContentData.hook'

export default function Register() {
    const navigate = useNavigate()

    const [formData, setFormData] = useState({})
    const [result, setResult] = useState('')
    const [show, setShow] = useState(false)
    // get contents from redux
    // const contents = useSelector((state) => state.content.contents)
    // find about and mission content
    // const consent = contents?.find(
    //     (content) => content.code === ContentCode.CONSENT
    // )
    const { isPending, error, data: consent } = ContentDataHook(ContentCode.CONSENT)
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    if (error) return 'Register Page: An error has occurred: ' + error.message
    // get Data
    const getData = (key) => {
        return formData.hasOwnProperty(key) ? formData[key] : ''
    }
    // Set data
    const setData = (key, value) => {
        return setFormData({ ...formData, [key]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setResult('')

        if (!formData.agree) {
            setResult('You must agree to the consent form')
            return
        }

        // console.log('formData', formData)
        if (!formData.username) {
            setResult('Username is required, it must be at least 4 characters long')
            return
        }
        if (!formData.password || !formData.repassword) {
            setResult('Password or confirm password is required!')
            return
        }
        if (formData.password !== formData.repassword) {
            setResult('Password and confirm password must be same!')
            return
        }
        if (!formData.fullname) {
            setResult('Full name is required, it must be at least 4 characters long')
            return
        }
        if (!formData.phone_number) {
            setResult('Phone number is required!')
            return
        }
        try {
            await register(formData)
            setFormData({})
            setResult("success")
            setTimeout(() => {
                navigate('/tires')
            }, 2000)
        } catch (err) {
            setResult(err.data?.message)
        }
    }

    function handleOk() {
        setData('agree', true)
        setShow(false)
    }

    function handleCancel() {
        setData('agree', false)
        setShow(false)
    }

    return (
        <>
            <BannerSection
                color='#fff'
                title='Welcome To Tiremaxx'
                background='/images/hero-section2.webp'
            // description='Tiremaxx'
            />
            <Navigation
                title='Register as a business user'
                titleLink='/register'
                imageUrl='/images/hero-section2.webp'
            />
            {consent && (
                <Modal title="Consent"
                    open={show}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={1000}
                    footer={null}
                >
                    <p className='desc-content ql-editor' dangerouslySetInnerHTML={{ __html: consent.content }}></p>
                    <p><br /></p>
                    <p><button className='common-button' onClick={handleOk}>I agree</button></p>
                </Modal>
            )}
            <section className="login-section">
                <div className="login-img">
                    <img width="600" src="/images/warehouse-tires.webp" alt="warehouse tires" />
                    <h3>Are You Looking For Tire Shops In Alberta?<br />
                        Contact Tiremaxx Corp Today!</h3>
                    <h4>Phone: <a href={`tel:${config.phone}`}>{config.phone}</a></h4>
                    <h4>Email: <a href={`mailto:${config.email}`}>{config.email}</a></h4>
                </div>
                <div className="register-form">
                    <form onSubmit={handleSubmit}>
                        <div className="input-group">
                            <label htmlFor="name">Email (*)</label>
                            <input className="common-input" value={getData('username')} onChange={(e) => setData('username', e.target.value)} type="email" name="username" id="email" placeholder="Enter your email" required />
                        </div>
                        <div className="input-group">
                            <label htmlFor="password">Password (*)</label>
                            <input className="common-input" value={getData('password')} onChange={(e) => setData('password', e.target.value)} type="password" name="password" id="password" placeholder="Enter your password" required />
                        </div>
                        <div className="input-group">
                            <label htmlFor="repassword">Confirm Password (*)</label>
                            <input className="common-input" value={getData('repassword')} onChange={(e) => setData('repassword', e.target.value)} type="password" name="repassword" id="repassword" placeholder="Confirm your password" required />
                        </div>
                        <div className="input-group">
                            <label htmlFor="fullname">Full Name / Company Name (*)</label>
                            <input className="common-input" value={getData('fullname')} onChange={(e) => setData('fullname', e.target.value)} type="text" name="fullname" id="fullname" placeholder="Enter your full name" required />
                        </div>
                        <div className="input-group">
                            <label htmlFor="amvic">AMVIC Number (Optional)</label>
                            <input className="common-input" value={getData('amvic')} onChange={(e) => setData('amvic', e.target.value)} type="text" name="amvic" id="amvic" placeholder="Enter AMVIC number" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="phone_number">Phone Number (*)</label>
                            <input className="common-input" value={getData('phone_number')} onChange={(e) => setData('phone_number', e.target.value)} type="text" name="phone_number" id="phone_number" placeholder="Enter your phone" required />
                        </div>
                        <div className="input-group">
                            <label className='agreement'>
                                <Checkbox onChange={(e) => setData('agree', e.target.checked)} checked={formData.agree}> I agree to the agreement</Checkbox>
                                <button type="button" className='btn-readit' onClick={() => setShow(true)}>Read it</button>
                            </label>
                        </div>
                        <div className="input-group">
                            <Link to="/login">Have an account? Login Now</Link>
                            <button type="submit" className="common-button">REGISTER</button>
                        </div>
                        {result &&
                            <div className={result === 'success' ? 'result-success' : 'result-error'}>
                                <label><AiFillInfoCircle /> {result}</label>
                            </div>
                        }
                    </form>
                </div>
            </section>
        </>
    )
}
