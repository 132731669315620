import './Navbar.css'
import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { AiOutlineMenu, AiOutlineClose, AiOutlineDown, AiFillMessage } from 'react-icons/ai'
import { AiOutlineUser, AiFillPhone } from 'react-icons/ai'
import { AiFillSetting, AiOutlineLogout } from 'react-icons/ai'
import { config } from '@config'
import NavMenuItems from '@data/NavMenuItems'
import { Dropdown, Space, Button, Popover, Tooltip, Drawer, Avatar } from 'antd'
import MessageForm from './MessageForm'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterTypes, setNewsFilterTypes } from '@reducers/contentReducer'
import NavbarTop from './NavbarTop'
import { logout } from '@services/api.service'
// import helper from '@utils/helper'
// import { FaShoppingCart } from "react-icons/fa"
import { ProductTypes } from '@data/ProductCode'
import PWAPrompt from 'react-ios-pwa-prompt'

export default function Navbar() {

    const dispatch = useDispatch()

    const user = useSelector((state) => state.user.userinfo)
    const newsTypeList = useSelector((state) => state.content.newsTypeList)
    const productsTypeList = useSelector((state) => state.content.productsTypeList)
    const isMobile = useSelector((state) => state.content.isMobile)

    const [showMenu, setShowMenu] = useState(window.innerWidth > 960)
    const [showMessageForm, setShowMessageForm] = useState(false)
    const [showAddToHomeScreen, setShowAddToHomeScreen] = useState(isMobile)

    useEffect(() => {
        window.addEventListener('resize', () => {
            setShowMenu(window.innerWidth > 960)
        })



    }, [])

    const onClickMenuButton = () => {
        setShowMenu(!showMenu)
    }

    const onClickNavLink = () => {
        if (window.innerWidth > 960) return
        setShowMenu(false)
    }

    const handleLogout = async () => {
        try {
            await logout()
            localStorage.removeItem('userinfo')
            window.location.href = '/'
        } catch (err) {
            console.log(err)
        }

    }

    const items = NavMenuItems.map(item => {
        if (['Tires', 'Wheels', 'Articles'].includes(item.label)) {
            let url = '/tires/list'
            let dataList = []
            if (item.label === 'Articles') {
                url = '/articles'
                dataList = newsTypeList
            } else if (item.label === 'Wheels') {
                url = '/wheels'
                dataList = productsTypeList.filter(item => item.is_wheel === 1)
                // console.log('dataList', dataList)
                // dataList.push({ id: 0, title: 'Wheel Balance Weights' })
            } else if (item.label === 'Tires') {
                url = '/tires/list'
                dataList = ProductTypes
            }
            item.children = dataList.map(row => {
                if (item.label === 'Tires')
                    url = `/tires/list/${row.short}`
                return {
                    key: row.id,
                    label: row.title,
                    link: url,
                    onClick: () => {
                        if (item.label === 'Articles') {
                            dispatch(setNewsFilterTypes({ type: row.id, action: 'set' }))
                        } else if (item.label === 'Tires') {
                            dispatch(setFilterTypes({ type: row.id, action: 'set' }))
                        }
                    }
                }
            })
            if (item.label === 'Wheels') {
                item.children.push({
                    key: 'wheel-balance',
                    label: 'Wheel Balance Weights',
                    link: '/wheel-balance',
                })
            }
        }
        return item
    })

    return (
        <header>
            <PWAPrompt 
                onClose={() => setShowAddToHomeScreen(false)}
                appIconPath="/logo192.webp"
                isShown={showAddToHomeScreen}
            />
            <Drawer
                title="Send a message"
                placement="right"
                width={window.innerWidth < 960 ? '100%' : 500}
                onClose={() => setShowMessageForm(false)}
                open={showMessageForm}
            >
                <MessageForm />
            </Drawer>
            <NavbarTop />
            <nav className="navbar">
                <div className="nav-logo">
                    <Link to="/" onClick={onClickNavLink}>
                        <img src="/images/logo.webp" width={250} alt={config.appName} />
                    </Link>
                </div>
                <div className="nav-links" style={{ display: showMenu ? 'flex' : 'none' }}>
                    {items.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.children.length === 0 ? (
                                <NavLink to={item.link}>
                                    <Space className='nav-links-item'>{item.label}</Space>
                                </NavLink>
                            ) : (
                                <Dropdown key={item.key} menu={{
                                    items: item.children.map(item => {
                                        return {
                                            ...item,
                                            label: <Link to={item.link} onClick={item?.onClick}>{item.label}</Link>
                                        }
                                    })
                                }}>
                                    <NavLink to={item.link}>
                                        <Space className='nav-links-item'>{item.label}<AiOutlineDown /></Space>
                                    </NavLink>
                                </Dropdown>
                            )}
                        </React.Fragment>
                    ))}
                </div>
                <div className='nav-right' style={{ display: showMenu ? 'flex' : 'none' }}>
                    <Popover placement="bottom" title={null} content={<div>
                        <strong>CALL US</strong>
                        <h1><a href={`tel:${config.phone}`} alt='Tiremaxx.ca' style={{ color: '#000' }}>{config.phone}</a></h1>
                        <p>Our customer service experts
                            are ready to help!</p>
                    </div>} trigger="click">
                        <Button type="primary" size='large' shape="circle" style={{ backgroundColor: '#B33030' }} icon={<AiFillPhone size={24} />} />
                    </Popover>
                    <div className='nav-divider'>|</div>
                    {user ? (
                        <Dropdown menu={{
                            items: [
                                { key: '1', label: <Link to='/setting'>Change Password</Link>, icon: <AiFillSetting />, },
                                { key: '2', label: <Link onClick={handleLogout}>Logout</Link>, icon: <AiOutlineLogout /> },
                            ]
                        }}>
                            <Avatar size={48} src={<img src="/logo128.webp" alt={user.username} />} />
                        </Dropdown>
                    ) : (
                        <Tooltip title="Business Login" placement="bottom">
                            <Link to='/login'>
                                <Button type="primary" size='large' shape="circle" style={{ backgroundColor: '#19282F' }} icon={<AiOutlineUser size={24} />} />
                            </Link>
                        </Tooltip>
                    )}
                    <Tooltip title="Do you have any questions? please send us a message!" placement="bottom">
                        <Button type="primary" size='large' shape="circle" style={{ backgroundColor: '#19282F' }} icon={<AiFillMessage size={24} />} onClick={() => {
                            setShowMessageForm(true)
                        }} />
                    </Tooltip>
                    {/* <Link to="/shopping-cart">
                        <Button
                            type="primary"
                            size='large'
                            shape="circle"
                            style={{ backgroundColor: '#19282F' }}
                            icon={<FaShoppingCart size={24} />}
                        />
                    </Link> */}
                </div>
                <div className="nav-menu">
                    {showMenu ? (
                        <AiOutlineClose size={32} onClick={onClickMenuButton} />
                    ) : (
                        <AiOutlineMenu size={32} onClick={onClickMenuButton} />
                    )}
                </div>
            </nav>
        </header >
    )
}
