import React from 'react'
import { Modal } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setFilterBrands } from '@reducers/contentReducer'
import BrandsData from '@data/BrandsData'
import './Modal.css'

export default function ModalBrands({
    tireBrandOpen,
    setTireBrandOpen
}) {

    const dispatch = useDispatch()

    const brandList = BrandsData.brands
    // brandList.sort(function (a, b) {
    //     if (a.title < b.title) { return -1 }
    //     if (a.title > b.title) { return 1 }
    //     return 0
    // })

    return (
        <Modal
            title="Find tires by tire brands"
            centered
            open={tireBrandOpen}
            footer={null}
            onCancel={() => setTireBrandOpen(false)}
            width={1000}
            className={'modal-wrapper'}
        >
            <div className='tire-types-wrapper'>
                {brandList.map(item => (
                    <Link key={item.id} to={`/tires/brand/${item.short}`} onClick={() => {
                        dispatch(setFilterBrands({ brand: item.title, action: 'set' }))
                    }}>
                        <div className='tire-brands-item' key={item.id}>
                            <img src={item.logo} alt={item.title} />
                            <h3>{item.title}</h3>
                        </div>
                    </Link>
                ))}
            </div>
        </Modal>
    )
}
