const ServiceDetails = [
    {
        title: 'Tire change',
        desc: 'Changing tires is crucial for maintaining safety, especially in extreme climate conditions such as winter and summer. Our certified technicians and top of the line equipment will get you back on the road in no time.',
        image: '/images/1tire change.png',
        code: 'tire-change'
    },
    {
        title: 'Tire balancing',
        desc: 'Tire balancing is essential for preventing uneven tire wear and ensuring a smooth ride. By using small weights, imbalances in the wheels can be adjusted to ensure they are all even.',
        image: '/images/1tire balance.png',
        code: 'tire-balancing'
    },
    {
        title: 'Flat tire repair',
        desc: 'Flat tires can be caused by many factors. At Tiremaxx, we will inspect the damage thoroughly and give you expert advice on how to resolve the problem.',
        image: '/images/1flat tire.png',
        code: 'flat-tire-repair'
    },
    {
        title: 'Tire rotation',
        desc: 'Tire rotation is essential for promoting even tire wear, which extends the overall lifespan of your tires. Regularly rotating tires also enhances vehicle handling and improves fuel efficiency.',
        image: '/images/1tire rotation.png',
        code: 'tire-rotation'
    },
    {
        title: 'Wheel alignment',
        desc: 'Ever notice your car pulling to one direction? This could be an alignment issue. Wheel alignment is crucial for ensuring your tires wear evenly and your vehicle drives smoothly.',
        image: '/images/1tire alignment.png',
        code: 'wheel-alignment'
    },
    {
        title: 'Oil change',
        desc: 'Regular oil changes are essential for keeping your engine lubricated, preventing wear and tear, and ensuring optimal performance. Timely oil changes also help improve fuel efficiency and extend the life of your engine.',
        image: '/images/1oil change.png',
        code: 'oil-change'
    },
    {
        title: 'Financing',
        desc: 'Tiremaxx offers flexible financing options through our partners to help you manage the costs of your goods and services needs.',
        image: '/images/1financing.png',
        code: 'financing'
    },
    {
        title: 'Shipping',
        desc: 'We provide shipping across Canada at competitive rates. Contact us today and get a quote in minutes!',
        image: '/images/1shipping.png',
        code: 'shipping'
    },
    {
        title: 'Rims',
        desc: `New tires deserve new rims! Our rims order form has all the information we need to give you the best experience, so fill it out and we will get back to you ASAP.`,
        image: '/images/1rims.png',
        code: 'tire-rims'
    },
    {
        title: 'Tire Storage',
        desc: `At Tiremaxx, we understand that storing your off-season tires can be a challenge. Whether it's the bulky winter tires in the summer or your summer tires in the winter, finding the space at home to safely store them can be tough. That’s why we offer our convenient tire storage service!`,
        image: '/images/tire-storage.png',
        code: 'tire-storage'
    }
]

export default ServiceDetails