import './NewView.css'
import React from 'react'
import NewItem from './NewItem'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrentNewsId } from '@reducers/contentReducer'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import helper from '@utils/helper'
import { HelmetProvider, Helmet } from 'react-helmet-async'

export default function NewView({
  currentNews,
  // handleClickNews,
  newsList
}) {
  const dispatch = useDispatch()

  if (!currentNews) return null
  const linkTo = `/articles/${helper.formatTitleForLink(currentNews.title)}`

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: currentNews.title,
    image: [currentNews.picture],
    author: {
      '@type': 'Person',
      name: currentNews.author
    },
    publisher: {
      '@type': 'Organization',
      name: 'TireMaxx',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.tiremaxx.ca/images/logo.webp'
      }
    },
    datePublished: helper.formatDate(currentNews?.create_time * 1000),
    dateModified: helper.formatDate(currentNews?.update_time * 1000),
    mainEntityOfPage: 'https://www.tiremaxx.ca' + linkTo,
    articleBody: currentNews.description
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
      </HelmetProvider>
      <SeoTitleAndDesc
        title={currentNews.title}
        seo_title={currentNews.seo_title}
        seo_desc={currentNews.seo_desc}
      />
      <div className="new-detail">
        <div className="new-detail-content">
          <h3>{currentNews?.title}</h3>
          <p className="image">
            {currentNews?.picture && (
              <img src={currentNews?.picture} alt={currentNews?.title} />
            )}
          </p>
          <p
            className="desc desc-content ql-editor"
            dangerouslySetInnerHTML={{ __html: currentNews?.description }}
          ></p>
          <p className="date">
            Created Date: {helper.formatDate(currentNews?.create_time * 1000)}{' '}
            {currentNews?.update_time > 0 &&
              currentNews?.update_time !== currentNews?.create_time && (
                <>
                  Updated Date:{' '}
                  {helper.formatDate(currentNews?.update_time * 1000)}
                </>
              )}
          </p>
          {/* <p className='date'>Date: {currentNews?.create_time}</p> */}
          <p
            className="content desc-content ql-editor"
            dangerouslySetInnerHTML={{ __html: currentNews?.content }}
          ></p>
          {currentNews?.tags && (
            <p className="tags">Tags: {currentNews?.tags}</p>
          )}
        </div>
        <div className="new-detail-side">
          <div className="new-view-author">
            <h3>Tiremaxx</h3>
            <div className="author-content">
              <img src="/logo192.webp" alt={currentNews?.title} />
              <div className="author-desc">
                {/* <h4>Tiremaxx</h4> */}
                <p>
                  Are You Looking For Tire Shops In Alberta? Contact Tiremaxx
                  Corp Today!
                </p>
                <Link to="/contact">
                  <button className="common-button mb10">Contact Now</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="new-view-related">
            <h3>Related News</h3>
            {newsList.length > 0 &&
              newsList
                .filter((item) => item.id !== currentNews?.id)
                .slice(0, 4)
                .map((item, index) => (
                  <NewItem
                    key={index}
                    index={index}
                    currentRow={item}
                    isSide={true}
                    onClick={() => dispatch(setCurrentNewsId(item.id))}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  )
}
