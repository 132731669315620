import './NewItemCard.css'
import React from 'react'
import { Link } from 'react-router-dom'
import helper from '@utils/helper'
import { useDispatch } from 'react-redux'
import { setCurrentNewsId } from '@reducers/contentReducer'

export default function NewItem({
    currentRow,
    // onClick,
    // isSide
}) {
    const dispatch = useDispatch()

    if (!currentRow) return null

    const linkTo = `/articles/${helper.formatTitleForLink(currentRow.title)}`

    const onClick = () => {
        dispatch(setCurrentNewsId(currentRow.id))
    }

    return (

        <div className='new-item-card'>
            {currentRow?.picture &&
                <Link to={linkTo} onClick={onClick}><img src={currentRow?.picture} alt={currentRow?.title} /></Link>
            }
            <Link to={linkTo} onClick={onClick}>{currentRow?.title}</Link>
        </div>
    )
}
