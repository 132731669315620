import './NewItem.css'
import React from 'react'
import { Link } from 'react-router-dom'
import helper from '@utils/helper'
import { useDispatch } from 'react-redux'
import { setCurrentNewsId } from '@reducers/contentReducer'

export default function NewItem({
    currentRow,
    // onClick,
    isSide
}) {
    const dispatch = useDispatch()

    if (!currentRow) return null

    const linkTo = `/articles/${helper.formatTitleForLink(currentRow.title)}`

    const onClick = () => {
        dispatch(setCurrentNewsId(currentRow.id))
    }

    return (

        <div className='new-item' style={{ flexDirection: isSide ? 'column' : null }}>
            {currentRow?.picture &&
                <Link to={linkTo} onClick={onClick}><img src={currentRow?.picture} alt={currentRow?.title} /></Link>
            }
            <div className='new-item-content'>
                <h3><Link to={linkTo} onClick={onClick}>{currentRow?.title}</Link></h3>
                <p className='date'>{helper.formatDate(currentRow?.create_time * 1000)}</p>
                {/* <p className='date'>{currentRow?.create_time}</p> */}
                <p className='desc ql-editor' dangerouslySetInnerHTML={{ __html: currentRow?.description }}></p>
                <p></p>
                <p><Link to={linkTo} onClick={onClick}>Read it</Link></p>
            </div>
        </div>
    )
}
