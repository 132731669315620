import React from 'react'
import { Modal } from 'antd'
import { Link } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
// import { setFilterTypes } from '@reducers/contentReducer'
import './Modal.css'
import helper from '@utils/helper'

export default function ModalTires({
    tireTypeOpen,
    setTireTypeOpen,
    productsTypeList
}) {

    // const dispatch = useDispatch()

    return (
        <Modal
            title="Find tires by tire types"
            centered
            open={tireTypeOpen}
            footer={null}
            onCancel={() => setTireTypeOpen(false)}
            width={1000}
            className={'modal-wrapper'}
        >
            <div className='tire-types-wrapper'>

                {productsTypeList.map(item => {
                    return (
                        <div key={item.id} className="tire-types-item">
                            <Link to={`/tires/list/${helper.formatTitleForLink(item.title)}`}><img src={item.picture} alt={item.title} /></Link>
                            <Link to={`/tires/list/${helper.formatTitleForLink(item.title)}`}><h3>{item.title}</h3></Link>
                        </div>
                    )
                })}
            </div>
        </Modal>
    )
}
