import React from 'react'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
import ServiceSection from '@components/ServiceSection'
// import { useSelector } from 'react-redux'
import { ContentCode } from '@data/ContentData'
import './Wheels.css'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import ContentDataHook from '@hooks/ContentData.hook'

const wheels = [
    {
        id: 1,
        title: 'Alloy Wheels',
        description: 'Alloy Wheels',
        image: '/images/Alloy-Wheels.webp'
    },
    {
        id: 2,
        title: 'Steel Wheels',
        description: 'Steel Wheels',
        image: '/images/Steel-Wheels.webp'
    },
    {
        id: 3,
        title: 'TPMS',
        description: 'TPMS',
        image: '/images/TPMS - mx sensor.webp'
    }
]

export default function Wheels() {
    // get contents from redux
    // const contents = useSelector((state) => state.content.contents)
    // const productTypeList = useSelector((state) => state.content.productsTypeList)
    // console.log('productTypeList', productTypeList)
    // find about and mission content
    // const content = contents?.find(
    //     (content) => content.code === ContentCode.WHEELS
    // )

    const { isPending, error, data: content } = ContentDataHook(ContentCode.WHEELS)
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    if (error) return 'Wheels Balance Weight Page: An error has occurred: ' + error.message

    return (
        <>
            <SeoTitleAndDesc
                title={content?.title || 'Wheels'}
                seo_title={content?.seo_title}
                seo_desc={content?.seo_desc}
            />
            <BannerSection
                color='#fff'
                title='Wheels'
                background='/images/banner-products.webp'
            />
            <Navigation
                title='Wheels'
                titleLink='/wheels'
                imageUrl='/images/banner-products.webp'
            />
            <div className="wheels-section">
                <div className="wheels-content">
                    <h3>Wheels<span>&nbsp;</span></h3>
                    <div className="wheels-list">
                        {wheels.map((wheel) => (
                            <div key={wheel.id} className='wheels-list-item'>
                                <img src={wheel.image} alt={wheel.title} />
                                <h4>{wheel.title}</h4>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            {content && (
                <ServiceSection
                    // title='WHAT WE DO?'
                    // subtitle={content.title}
                    description={content?.content}
                    productTypeList={[]}
                />
            )}
        </>
    )
}
