import './CartSide.css'
import React from 'react'

export default function CartSide() {

    return (
        <section className="cart-side">
            <div className="cart-side-item">
                <h3>Summary</h3>
                <div className="cart-side-line">
                    Subtotal: $0.00
                </div>
                <div className="cart-side-line">
                    Taxes: $0.00
                </div>
                <div className="cart-side-line">
                    Total: $0.00
                </div>
            </div>
        </section>
    )
}