import './ProductSection.css'
import React from 'react'
import { Link } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
// import { setFilterTypes } from '@reducers/contentReducer'
import helper from '@utils/helper'
import MyImage from './MyImage'

export default function ProductSection({
    title,
    productTypeList
}) {

    // const dispatch = useDispatch()

    return (
        <section className="product-section">
            {title && (
                <div className="product-header">
                    <h3>{title}<span>&nbsp;</span></h3>
                </div>
            )}
            <section className="tire-types-section">
                <div className="tire-types-content">
                    {productTypeList && productTypeList.map(item => {
                        return (
                            <div key={item.id} className="tire-types-content-item">
                                <Link to={`/tires/list/${helper.formatTitleForLink(item.title)}`}>
                                    {/* <img src={item.picture} alt={item.title} /> */}
                                    <MyImage src={item.picture} alt={item.title} />
                                </Link>
                                <Link to={`/tires/list/${helper.formatTitleForLink(item.title)}`}><h3>{item.title}</h3></Link>
                            </div>
                        )
                    })}
                </div>
            </section>
        </section>
    )
}
