import React, { useState } from 'react'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
import { AiFillInfoCircle } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { config } from '@config'
import './User.css'
import { Modal, Checkbox } from 'antd'

import { useDispatch } from 'react-redux'
import { ContentCode } from '@data/ContentData'
import { login } from '@services/api.service'
import { setUser } from '@reducers/userReducer'
import ContentDataHook from '@hooks/ContentData.hook'

const initFormData = {
    username: '',
    password: '',
    agree: false
}

export default function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(initFormData)
    const [result, setResult] = useState('')
    const [show, setShow] = useState(false)
    // get contents from redux
    // const contents = useSelector((state) => state.content.contents)
    // find about and mission content
    // const consent = contents?.find(
    //     (content) => content.code === ContentCode.CONSENT
    // )

    const { isPending, error, data: consent } = ContentDataHook(ContentCode.CONSENT)
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    if (error) return 'Login Page: An error has occurred: ' + error.message

    // Set data
    const setData = (key, value) => {
        return setFormData({ ...formData, [key]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setResult('')
        // console.log('formData', formData)

        if (!formData.agree) {
            setResult('You must agree to the consent form')
            return
        }
        if (!formData.username) {
            setResult('Username is required!')
            return
        }
        if (formData.username.length < 4) {
            setResult('Username must be between 4 and 100 characters!')
            return
        }
        if (!formData.password) {
            setResult('Password is required!')
            return
        }
        if (formData.password.length < 6) {
            setResult('Password must be at least 6 characters or digits!')
            return
        }
        try {
            const result = await login(formData)
            // console.log('result', result)
            dispatch(setUser(result))
            // setUser(result)
            // localStorage.setItem('userinfo', JSON.stringify(result))
            setFormData(initFormData)
            setResult('success')
            setTimeout(() => {
                navigate('/tires')
            }, 2000)
        } catch (err) {
            setResult(err.data?.message)
        }
    }

    function handleOk() {
        setData('agree', true)
        setShow(false)
    }

    function handleCancel() {
        setData('agree', false)
        setShow(false)
    }

    return (
        <>
            <BannerSection
                color='#fff'
                title='Welcome To Tiremaxx'
                background='/images/hero-section2.webp'
            // description='Tiremaxx'
            />
            <Navigation
                title='Business Login'
                titleLink='/login'
                imageUrl='/images/hero-section2.webp'
            />

            {consent && (
                <Modal title="Consent"
                    open={show}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={1000}
                    footer={null}
                >
                    <p className='desc-content ql-editor' dangerouslySetInnerHTML={{ __html: consent.content }}></p>
                    <p><br /></p>
                    <p><button className='common-button' onClick={handleOk}>I agree</button></p>
                </Modal>
            )}

            <section className="login-section">
                <div className="login-form">
                    <form onSubmit={handleSubmit}>
                        <div className="input-group">
                            <label htmlFor="name">Email</label>
                            <input className="common-input"
                                value={formData.username}
                                onChange={(e) => setData('username', e.target.value)}
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                required />
                        </div>
                        <div className="input-group">
                            <label htmlFor="name">Password</label>
                            <input className="common-input"
                                value={formData.password}
                                onChange={(e) => setData('password', e.target.value)}
                                type="password"
                                name="password"
                                placeholder="Enter your password"
                                required />
                        </div>
                        <div className="input-group">
                            <label className='agreement'>
                                <Checkbox onChange={(e) => setData('agree', e.target.checked)} checked={formData.agree}> I agree to the agreement</Checkbox>
                                <button type="button" className='btn-readit' onClick={() => setShow(true)}>Read it</button>
                            </label>
                        </div>
                        <div className="input-group">
                            <Link to="/register">Don't have an account? Register now</Link>
                            <button type="submit" className="common-button">LOGIN</button>
                        </div>
                        {result &&
                            <div className={result === 'success' ? 'result-success' : 'result-error'}>
                                <label><AiFillInfoCircle /> {result}</label>
                            </div>
                        }
                    </form>
                </div>
                <div className="login-img">
                    <img width="400" src="/images/login-side.webp" alt="warehouse tires" />
                    <h3>Are You Looking For Tire Shops In Alberta?<br />
                        Contact Tiremaxx Today!</h3>
                    <h4>Phone: </h4><a href={`tel:${config.phone}`}>{config.phone}</a>
                    <h4>Email: </h4> <a href={`mailto:${config.email}`}>{config.email}</a>
                </div>
            </section>
        </>
    )
}
