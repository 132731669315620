import React from 'react'

export default function ProductIcon3PMS({
    product
}) {
    if (!product) return null
    return (
        <>
            {(product.brands === 'Road Cruza' ||
                product.title?.toLowerCase()?.indexOf('road cruza') !== -1 ||
                product.type_name === 'Winter Tires' ||
                product.type_name === 'All Weather Tires'
            ) && (
                    <img className='item-img-3pms' src="/3pms_icon.webp" alt="Three-Peak Mountain Snowflake" />
                )}
        </>
    )
}
